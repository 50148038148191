@import './sass/_grid';

.wrapper {
  min-height: calc(100vh - 124px);
  margin: 0 auto;
  max-width: 90rem;
  // padding: 1rem 1.5rem 3rem 0;
  display: flex;
}

.nav, .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem !important;
}

.content {
  flex-grow: 1;
  padding: 1rem;
}

.nav {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  flex-basis: 15rem;
  width: 100%;
}
