.form-group {
  margin-bottom: 1rem;
  padding: 0;
}

.form-group--flex {
  display: flex;
  justify-content: space-between;
}

.form-control {
  appearance: none;
  border-radius: var(--border-radius);
  border: 1px solid var(--dove);
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  resize: vertical;
  transition: border 0.08s ease-in-out;
  width: 100%;

  &::placeholder {
    color: var(--slate-light);
  }

  &:focus {
    border-color: var(--slate-medium);
  }
}

.form-control--monospaced {
  color: var(--slate);
  font-family: "Lucida Console", Monaco, monospace;
}

.form-fieldset {
  margin-bottom: 2rem;
}

.form-asterisk {
  color: var(--rouge);
}

select {
  cursor: pointer;
}

fieldset {
  border: 0;
  padding: 0;
}

textarea {
  min-height: 100px;
}

.form-row {
  display: flex;
  flex-direction: column;

  @media all and (min-width: $desktop) {
    flex-direction: row;
    justify-content: space-between;

    .form-group {
      flex: 0 0 calc(50% - 1rem);
    }
  }
}

.form-row--three-col {
  @media all and (min-width: $desktop) {
    .form-group {
      flex: 0 0 calc(33.333% - 1rem);
    }
  }
}

.form-column {
  padding: 0 2rem 0 1rem;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;

  button {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.submit-row {
    display: flex;
    flex-wrap: wrap;
    button {
        margin: 5px;
    }
}
