/**
 * @license
 * MyFonts Webfont Build ID 3806597, 2019-09-11T14:10:16-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Nutmeg-Book by W Foundry
 * URL: https://www.myfonts.com/fonts/without-foundry/nutmeg/book/
 *
 * Webfont: NutmegHeadline-ExtraBold by W Foundry
 * URL: https://www.myfonts.com/fonts/without-foundry/nutmeg/headline-extra-bold/
 *
 * Webfont: Nutmeg-BookItalic by W Foundry
 * URL: https://www.myfonts.com/fonts/without-foundry/nutmeg/book-italic/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3806597
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright &#x00A9; 2017 by Salvador Rodr&#x00ED;guez. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/

@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700");

/* @import must be at top of file, otherwise CSS will not work */
// temporarily remove license reporting until they fix their stuff
// @import url("//hello.myfonts.net/count/3a1585");

@font-face {font-family: 'Nutmeg-Book';src: url(../assets/3A1585_0_0.eot);src: url(../assets/3A1585_0_0.eot?#iefix) format('embedded-opentype'),url(../assets/3A1585_0_0.woff2) format('woff2'),url(../assets/3A1585_0_0.woff) format('woff'),url(../assets/3A1585_0_0.ttf) format('truetype');}

@font-face {
  font-family: 'Nutmeg-ExtraBold';
  src: url(../assets/Nutmeg-ExtraBold.eot);
  src: url(../assets/Nutmeg-ExtraBold.eot?#iefix) format('embedded-opentype'), url(../assets/Nutmeg-ExtraBold.woff2) format('woff2'), url(../assets/Nutmeg-ExtraBold.woff) format('woff'), url(../assets/Nutmeg-ExtraBold.ttf) format('truetype');
}

@font-face {font-family: 'Nutmeg-BookItalic';src: url(../assets/3A1585_2_0.eot);src: url(../assets/3A1585_2_0.eot?#iefix) format('embedded-opentype'),url(../assets/3A1585_2_0.woff2) format('woff2'),url(../assets/3A1585_2_0.woff) format('woff'),url(../assets/3A1585_2_0.ttf) format('truetype');}
