body {
  /* Colors */
  --primary: #0A24CC;
  --primary-light: #3549c9;
  --shopify-green: #65b137;

  --dove: #CCC;
  --dove-light: #ecf0f1;

  --white: #FFFFFF;
  --slate-dark: #202020;
  --slate: #383838;
  --slate-medium: #656565;
  --slate-light: #909090;

  --rouge: #EC4300;

  /* Borders */
  --border-radius: 4px;
  --border-outline: 1px solid #CACACA;

  /* Box Shadow */
  --box-shadow: 0 3px 10px 0 rgba(0,0,0,0.08);

  /* Font Weight */
  --font-weight-demi: 600;

  /* Easing */
  --ease: 0.12 ease-in-out;
}
