@import '../../sass/_grid';

.sidebar {
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 0.5rem;
  border-right: 1px solid;
  border-color:#dee2e6;
}

.container {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  flex-basis: 15rem;
  width: 100%;
}

.logoWrap {
  padding: 0 0.25rem 0.5rem 0.25rem;
}

.userInfoWrap {
  align-items: center;
  display: flex;
  text-transform: capitalize;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.search {
  padding-bottom: 1rem;
  // There is a bug in downshift where it fires a menu-close event if you type a
  // character that causes the contents of the input to scroll right. Making the
  // input larger helps mitigate that.
  width: 100%;
}

.userAvatar {
  border-radius: 50%;
  height: 1.75rem;
  margin-right: 0.75rem;
  width: 1.75rem;
}
