@use '../colors';
@use '../variables' as vars;

// Tech debt from a very old, bad shopify decision to globally modify heading tags
.legacy-title {
  line-height: 1.25;
  margin-bottom: vars.$gutter-small;
  font-family: vars.$heading;
  font-weight: normal;
  font-style: normal;
}

// Tech debt from a very old, bad decision to globally modify p tags
.legacy-p {
  font-size: 1rem;
  line-height: 1.6;
}
