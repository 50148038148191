.react_modal {
  background: white;
  border-radius: var(--border-radius);
  margin: 3vh 1rem;
  max-width: 36rem;
  padding: 1rem;
  position: static;
  max-height: 94vh;

  @media all and (min-width: $desktop) {
    margin: 3vh auto 0;
  }
}

.ReactModal__Content {
  border: none;
  outline: none;
}
