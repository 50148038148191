@use './_colors' as colors;

$container-width: 78.75rem;   // 1260px
$nav-assumed-height: 70px;

// -------------------------------------
// Gutters
// -------------------------------------
$gutter: 1rem;
$gutter-xsmall: $gutter / 4;
$gutter-small: $gutter / 2;
$gutter-medium: $gutter * 1.5;
$gutter-large: $gutter * 4;

// -------------------------------------
// Border Radius
// -------------------------------------

$border-radius: 0.375rem;
$border-radius-small: $border-radius / 2;
$border-radius-large: $border-radius * 2;

// -------------------------------------
// Font Families
// -------------------------------------
$heading: 'Nutmeg-ExtraBold', Sans-Serif;
$heading-italic: 'Nutmeg-BookItalic', Sans-Serif;
$heading-book: 'Nutmeg-Book', Sans-Serif;
$sans: 'IBM Plex Sans', Sans-Serif;

// -------------------------------------
// Font Sizes
// -------------------------------------
$font-size-base: 16px;

$font-size-xxxl: 2.5rem;              // 40px
$font-size-xxl: 2.25rem;              // 36px
$font-size-xlarge: 1.75rem;           // 28px
$font-size-large: 1.5rem;             // 24px
$font-size-medium-large: 1.25rem;     // 20px
$font-size-medium: 1.125rem;          // 18px
$font-size-normal: 1rem;              // 16px
$font-size-small: 0.875rem;           // 14px
$font-size-xsmall: 0.75rem;           // 12px
$font-size-xxsmall: 0.6875rem;        // 11px

// -------------------------------------
// Spacing
// -------------------------------------
$space-01: 0.125rem; //  2px
$space-02: 0.25rem;  //  4px
$space-03: 0.5rem;   //  8px
$space-04: 0.75rem;  // 12px
$space-05: 1rem;     // 16px
$space-06: 1.5rem;   // 24px
$space-07: 2rem;     // 32px
$space-08: 2.5rem;   // 40px
$space-09: 3rem;     // 48px
$space-10: 4rem;     // 64px
$space-11: 5rem;     // 80px


// -------------------------------------
// Font Weights
// -------------------------------------
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-xbold: 800;

// -------------------------------------
// Easing
// -------------------------------------
$ease: 0.12s ease-in-out;

$empty-gradient: linear-gradient(40deg, colors.$dove-light, colors.$dove-xlight);
$header-shadow: 0 8px 20px 0 rgba(0,0,0,.08);
$quick-add-shadow: 0 4px 12px 0 rgba(0,0,0,0.13);
$image-text-shadow: 0 0 20px colors.$black;