* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Nunito', sans-serif;
}

body {
  margin: 0;
}

main {
  transition: transform 0.3s ease-in-out;
}

.wrapper-inner {
  padding: 1rem 1.5rem 3rem;
}

.draft {
  color: gray;
}
.available {
  color: green;
}
.deleted {
  color: red;
}
.archived {
  color: gray;
}

.container {
  border: 1px solid var(--dove);
  border-radius: 0.5rem;
  margin: 0 0 1rem 0;
  padding: 1rem;
}

.container-top-half {
  border-bottom: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
}
.container-bottom-half {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: black;
  &.draft {
    background-color: rgb(205, 203, 203);
  }
  &.available {
    background-color: rgb(185, 215, 185);
  }
  &.deleted {
    background-color: rgb(228, 188, 188);
  }
  &.archived {
    background-color: rgb(205, 203, 203);
  }
}

.shopify-container {
  border-color: var(--shopify-green);
}

.guidance {
  color: gray;
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.25rem 0.0rem 0.7rem 0.1rem;
  p {
    margin-bottom: 0;
  }
}

.footer-bar {
  background-color: rgb(205, 225, 213);
}

.nice-button {
  margin: 0.5rem 0 0.5rem;
}

ol, ul {
  display: block;
  list-style-type: disc;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}


// copy and pasted portal magic from mable-web
// It is probably possible to do this entirely within `Portal` + a `PortProvider`, but this works now doesn't it?
 #top-portal-root {
   position: sticky;
   /* hack to force relative positioning for fixed root child */
   transform: rotate(0);
   top: 0;
   z-index: 100;
   display: flex;
   flex-direction: column;
 }
 #top-fixed-portal-root {
   top: 100%;
   z-index: 6;
   display: flex;
   flex-direction: column;
   position: fixed;
   width: 100%;
   order: 9999999;
   pointer-events: none;
 }
 #bottom-portal-root {
   position: fixed;
   bottom: 0;
   width: 100%;
   z-index: 4;
   display: flex;
   flex-direction: column-reverse;
   pointer-events: none;
 }
 #root {
   position: relative;
   z-index: 0;
 }
 .mable-portal-node {
   pointer-events: auto;
 }
