.navbar {
  flex: 1;
}

.navbarList {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.navbarLink {
  font-size: large;
  color: #051B30;
  text-decoration: none;
  font-weight: var(--font-weight-demi);
  display: block;
  line-height: 2.75rem;
  padding: 0.5rem 0.875rem;
  position: relative;
  transition: color 0.12s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #202020;
  }

  &:after {
    background: var(--primary);
    bottom: 0;
    content: "";
    display: block;
    height: 0;
    left: 0.875rem;
    position: absolute;
    transition: height 0.12s ease-in-out;
    width: calc(100% - 1.75rem);
  }
}

.navbarLinkActive {
  &,
  &:hover {
    color: var(--primary);
  }

  &:after {
    height: 3px;
  }
}
