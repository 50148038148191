.wrapper {
  position: relative;
}

.input {
  min-width: 12rem;
  width: 100%;
  border-radius: 0.2rem;
  border: 1px solid #d4d5d6;
  padding-left: 1.9rem;
}

.searchIcon {
  position: absolute;
  top: 0.1rem;
  left: 0.5rem;
  bottom: 0.1rem;
  width: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}

.help {
  padding: 1rem;
}

.menu {
  outline: 0;
  position: absolute;
  background: white;
  z-index: 3;
  border: 1px solid #DFDFDF;
  border-radius: 4;
  width: auto;
  width: max-content;
  min-width: 25rem;
  max-width: 50rem;
  box-sizing: border-box;
  box-shadow: 0 7px 19px rgba(#000000, 8%);
  transition: 0.3s box-shadow;

  &:hover {
    box-shadow: 0 7px 19px rgba(#000000, 12%);
  }

  &--closed {
    box-shadow: 0 7px 19px transparent;
    width: 0;
    min-width: 0;
    border: none;
    visibility: hidden;
  }
}

.options {
  max-height: 20rem;
  overflow-y: auto;
  padding: 0.5rem 0;
  position: relative;
  background: white;
}

.option {
  display: flex;
  align-items: center;
  color: #515151;
  background: white;
  transition: 0.3s, background 0.2s;
  cursor: pointer;
  padding: 0.25rem 1rem;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--disabled {
    cursor: not-allowed;
    text-decoration: line-through;
    color: #A3A3A3;
  }
  &--selected {
    color: #000000;
  }
  &--highlighted {
    color: #000000;
  }

  &__decorator {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
}

.selectedOption {
  color: #000000;
  background: #999;
}
