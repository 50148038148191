.table-wrap {
  box-shadow: var(--box-shadow);
  margin: 1em 0 0;
  overflow-x: scroll;
  width: 100%;
}

.table {
  background: var(--white);
  border-radius: var(--border-radius);
  border-collapse: collapse;
  min-width: 100%;
  overflow: hidden;

  .table-head {
    background-color: var(--slate-medium);
    text-align: left;

    .table-cell {
      color: var(--white);
      cursor: pointer;
      font-weight: var(--font-weight-demi);
    }
  }
}

.table-row {
  &:hover {
    .table-cell {
      background: var(--dove-light);
    }

    .table-cell__icon {
      opacity: 1;
    }
  }
}

.table-row--link {
  cursor: pointer;
}

.table-cell {
  padding: 1rem 1.325rem;
  transition: background 0.12s ease-in-out;

  .table-cell__icon {
    width: 0.875rem;
    height: 0.875rem;
    opacity: 0.375;
    transition: opacity 0.12s ease-in-out;
  }

  a {
    color: var(--slate-light);
    position: relative;
    text-decoration: none;

    &:hover {
      color: var(--slate-dark);
    }
  }
}

.table-cell--monospaced {
  font-family: "Lucida Console", Monaco, monospace;
  color: var(--slate-light);
}
